import {globalHistory} from "@reach/router";
import React, {FC, useContext, useEffect, useState} from 'react';
import SSPContentExt from '../lib/sspcontentext';
import SSPContentVO from '../lib/sspcontentext/model/SSPContentVO';
import {getLanguage} from "./language";

type CmsDataProviderContextType = {
    loading: boolean,
    data: Maybe<SSPContentVO>,
    error: Maybe<string>
}

const defaultContextValue = {
    loading: true,
    data: null,
    error: null
}

const CmsDataProviderContext = React.createContext<CmsDataProviderContextType>(defaultContextValue);

export const CmsDataProvider: FC = ({children}) => {
    const [sspContentExt, setSspContentExt] = useState<Maybe<SSPContentExt>>(null);
    const [value, setValue] = useState<CmsDataProviderContextType>(defaultContextValue);

    useEffect(() => {
        setValue({loading: true, data: null, error: null})
        const initialize = async () => {
            try {
  /*              const {location} = globalHistory
                const urlParameters = new URLSearchParams((location.search.substring(1)))
                // default language is english*/
                const region = getLanguage() || "en-GB"

                const sspContentExt = SSPContentExt.instance;
                sspContentExt.configure('https://d1y95afw45tw3u.cloudfront.net');
                sspContentExt.setCurrentLanguage(region);

                await sspContentExt.initWithDevice('Micros_0')
                const model = sspContentExt.startModel as SSPContentVO;

                setSspContentExt(sspContentExt)
                setValue({loading: false, data: model, error: null})
            } catch (error) {
                console.error('error initializing sspcontent extension');
                setValue({loading: false, data: null, error: error.message})
            }
        }

        initialize();
    }, [])

    return <CmsDataProviderContext.Provider value={value}>
        {children}
    </CmsDataProviderContext.Provider>
}

export const useCmsData = () => useContext(CmsDataProviderContext)
