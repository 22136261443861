// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookiePolicy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-imprint-index-tsx": () => import("./../../../src/pages/imprint/index.tsx" /* webpackChunkName: "component---src-pages-imprint-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacyPolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */)
}

