import { globalHistory } from "@reach/router"

export const getLanguage = (): string => {
  const { location } = globalHistory
  const urlParameters = new URLSearchParams(location.search.substring(1))

  return urlParameters.get("region") || ""
}

export const getCmsLanguage = (): string => {
  if (getLanguage() === "de_DE") {
    return "de-DE"
  } else if (getLanguage() === "zh_CN") {
    return "zh-CN"
  } else {
    return "en-GB"
  }
}

export const transformLanguageForCms = (language: string): string => {
  if (language === "de_DE") {
    return "de-DE"
  } else if (language === "zh_CN") {
    return "zh-CN"
  } else {
    return "en-GB"
  }
}

export const mapLanguageToCmsLanguage = (uiLanguage: string): string => {
  switch (uiLanguage) {
    case "de_DE":
      return "de-DE"
    case "zh_CN":
      return "zh-CN"
    default:
      return "en-GB"
  }
}

export const getUrlTimezone = (): string => {
  switch (getLanguage()) {
    case "de_DE":
      return "UTC+1" //'CET' //'UTC+1'
    case "en_GB":
      return "UTC+1" //'CET' //'UTC+1'
    case "es_ES":
      return "UTC+1" //'CET' //'UTC+1'
    case "en_US":
      return "UTC-5" //'EST' //'UTC-5'
    case "es_MX":
      return "UTC-5" //'EST' //'UTC-5'
    case "en_CN":
      return "UTC+8" //'CST' //'UTC+8'
    case "zh_CN":
      return "UTC+8" //'CST'//'UTC+8'
    default:
      return "UTC+1" //'CET' //'UTC+1'
  }
}

export const getUTCOffsetUrl = (): number => {
  switch (getLanguage()) {
    case "de_DE":
      return 1
    case "en_GB":
      return 1
    case "es_ES":
      return 1
    case "en_US":
      return -4
    case "es_MX":
      return -4
    case "en_CN":
      return 8
    case "zh_CN":
      return 8
    default:
      return 1
  }
}

export const convertLanguageToUtcOffset = (language: string): number => {
  switch (language) {
    case "de_DE":
      return 1
    case "en_GB":
      return 1
    case "es_ES":
      return 1
    case "en_US":
      return -5
    case "es_MX":
      return -5
    case "en_CN":
      return 8
    case "zh_CN":
      return 8
    default:
      return 1
  }
}

export const convertUserLanguageToTimezone = (language: string): string => {
  switch (language) {
    case "de_DE":
      return "UTC+1" //'CET' //'UTC+1'
    case "en_GB":
      return "UTC+1" //'CET' //'UTC+1'
    case "es_ES":
      return "UTC+1" //'CET' //'UTC+1'
    case "en_US":
      return "UTC-5" //'EST' //'UTC-5'
    case "es_MX":
      return "UTC-5" //'EST' //'UTC-5'
    case "en_CN":
      return "UTC+8" //'CST' //'UTC+8'
    case "zh_CN":
      return "UTC+8" //'CST'//'UTC+8'
    default:
      return "UTC+1" //'CET' //'UTC+1'
  }
}

export const getTimezoneUrlString = (): string => {
  if (getLanguage() === "en_US" || getLanguage() === "es_MX") {
    return "America/New_York"
  } else if (getLanguage() === "en_CN" || getLanguage() === "zh_CN") {
    return "Asia/Shanghai "
  } else {
    return "Europe/Zurich"
  }
}

export const getDayTranslation = (
  dayNumber: number,
  textMap: Map<string, string>
): string => {
  switch (dayNumber) {
    case 0:
      return textMap.get("agenda.dateSunday")
    case 1:
      return textMap.get("agenda.dateMonday")
    case 2:
      return textMap.get("agenda.dateTuesday")
    case 3:
      return textMap.get("agenda.dateWednesday")
    case 4:
      if (textMap.get("agenda.dateThursday") !== undefined) {
        return textMap.get("agenda.dateThursday")
      } else {
        return new Date("03.25.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 5:
      if (textMap.get("agenda.dateFriday") !== undefined) {
        return textMap.get("agenda.dateFriday")
      } else {
        return new Date("03.26.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 6:
      if (textMap.get("agenda.dateSaturday") !== undefined) {
        return textMap.get("agenda.dateSaturday")
      } else {
        return new Date("03.27.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 6:
      if (textMap.get("agenda.dateSaturday") !== undefined) {
        return textMap.get("agenda.dateSaturday")
      } else {
        return new Date("03.27.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 6:
      if (textMap.get("agenda.dateSaturday") !== undefined) {
        return textMap.get("agenda.dateSaturday")
      } else {
        return new Date("03.27.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 6:
      if (textMap.get("agenda.dateSaturday") !== undefined) {
        return textMap.get("agenda.dateSaturday")
      } else {
        return new Date("03.27.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    case 6:
      if (textMap.get("agenda.dateSaturday") !== undefined) {
        return textMap.get("agenda.dateSaturday")
      } else {
        return new Date("03.27.2021").toLocaleDateString(getCmsLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
    default:
      return "Translation missing"
  }
}

export const getFurioosId = (): string => {
  if (getLanguage() === "en_US" || getLanguage() === "es_MX") {
    //Americas
    return "138.455" //'404.091'
  } else if (getLanguage() === "en_CN") {
    //ASIA
    return "731.015" //'580.230'
  } else {
    //EMEA
    return "212.554" //'100.564'
  }
}
