"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseW3CDTF = void 0;
exports.parseW3CDTF = function (str) {
    var finalDate;
    try {
        var dateStr = str.substring(0, str.indexOf('T'));
        var timeStr = str.substring(str.indexOf('T') + 1, str.length);
        var dateArr = dateStr.split('-');
        var year = Number(dateArr.shift());
        var month = Number(dateArr.shift());
        var date = Number(dateArr.shift());
        var multiplier = void 0;
        var offsetHours = void 0;
        var offsetMinutes = void 0;
        var offsetStr = void 0;
        if (timeStr.indexOf('Z') !== -1) {
            multiplier = 1;
            offsetHours = 0;
            offsetMinutes = 0;
            timeStr = timeStr.replace('Z', '');
        }
        else if (timeStr.indexOf('+') !== -1) {
            multiplier = 1;
            offsetStr = timeStr.substring(timeStr.indexOf('+') + 1, timeStr.length);
            offsetHours = Number(offsetStr.substring(0, offsetStr.indexOf(':')));
            offsetMinutes = Number(offsetStr.substring(offsetStr.indexOf(':') + 1, offsetStr.length));
            timeStr = timeStr.substring(0, timeStr.indexOf('+'));
        }
        else {
            multiplier = -1;
            offsetStr = timeStr.substring(timeStr.indexOf('-') + 1, timeStr.length);
            offsetHours = Number(offsetStr.substring(0, offsetStr.indexOf(':')));
            offsetMinutes = Number(offsetStr.substring(offsetStr.indexOf(':') + 1, offsetStr.length));
            timeStr = timeStr.substring(0, timeStr.indexOf('-'));
        }
        var timeArr = timeStr.split(':');
        var hour = Number(timeArr.shift());
        var minutes = Number(timeArr.shift());
        var secondsArr = (timeArr.length > 0) ? String(timeArr.shift()).split('.') : null;
        var seconds = (secondsArr !== null && secondsArr.length > 0) ? Number(secondsArr.shift()) : 0;
        var milliseconds = (secondsArr !== null && secondsArr.length > 0) ? Number(secondsArr.shift()) : 0;
        var utc = Date.UTC(year, month - 1, date, hour, minutes, seconds, milliseconds);
        var offset = (((offsetHours * 3600000) + (offsetMinutes * 60000)) * multiplier);
        finalDate = new Date(utc - offset);
        if (finalDate.toString() === 'Invalid Date') {
            throw new Error('This date does not conform to W3CDTF.');
        }
    }
    catch (err) {
        var eStr = "Unable to parse the string [" + str + "] into a date.";
        eStr += "The internal error was: " + err.toString();
        throw new Error(eStr);
    }
    return finalDate;
};
