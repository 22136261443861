"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TimeRangeAccessor = (function () {
    function TimeRangeAccessor(startHour, startMinute, endHour, endMinute) {
        if (endHour === void 0) { endHour = -1; }
        if (endMinute === void 0) { endMinute = -1; }
        this.startHour = startHour < 0 ? 0 : startHour > 23 ? 23 : startHour;
        this.startMinute = startMinute < 0 ? 0 : startMinute > 59 ? 59 : startMinute;
        this.endHour = endHour;
        if (this.endHour !== -1) {
            this.endHour = endHour < 0 ? 0 : endHour > 23 ? 23 : endHour;
            this.endMinute = endMinute < 0 ? 0 : endMinute > 59 ? 59 : endMinute;
        }
        else {
            this.endMinute = -1;
        }
    }
    Object.defineProperty(TimeRangeAccessor.prototype, "hasEnd", {
        get: function () {
            return this.endHour > -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TimeRangeAccessor.prototype, "startToday", {
        get: function () {
            var now = new Date();
            now.setHours(this.startHour, this.startMinute, 0, 0);
            return now;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TimeRangeAccessor.prototype, "endToday", {
        get: function () {
            var now = new Date();
            if (this.hasEnd) {
                now.setHours(this.endHour, this.endMinute, 0, 0);
            }
            else {
                now.setHours(23, 59, 59, 999);
            }
            return now;
        },
        enumerable: false,
        configurable: true
    });
    TimeRangeAccessor.prototype.isInRange = function (date) {
        var start = this.startToday;
        if (!this.hasEnd) {
            return date.getTime() >= start.getTime();
        }
        var end = this.endToday;
        return date.getTime() >= start.getTime() && date.getTime() < end.getTime();
    };
    return TimeRangeAccessor;
}());
exports.default = TimeRangeAccessor;
