import React from 'react'
import { Helmet } from 'react-helmet'
import {CmsDataProvider} from "./src/utility/CmsDataProvider";
import {LoginStateProvider} from './src/api/login/loginState'
import {globalHistory} from "@reach/router";
import {updateTracking} from "./src/utility/analyticx";
import {TrackingUtil} from "./src/utility/tracking";
import {setCurrentPath} from "./src/utility/navigationHistory";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const wrapRootElement = ({element}) => {

    updateTracking()
    TrackingUtil.pushToDataLayer({event: "Loading"})

    globalHistory.listen(({action}) => {
       /* if (action === 'PUSH') {*/
            updateTracking()
            TrackingUtil.pushToDataLayer({event: "Navigate"})

            setCurrentPath(window.location.pathname)

        // scans for media players on new page
        window._paq.push(['MediaAnalytics::scanForMedia']);
        /*}*/

    })


    const initTracking = () => {
        window.dataLayer = window.dataLayer || []
        function gtag(){window.dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-4X3HTNR1H8');
    }

    return (
        <>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-4X3HTNR1H8"></script>
                <script>{initTracking()}</script>
            </Helmet>

            <CmsDataProvider>
                <LoginStateProvider>
                    {element}
                </LoginStateProvider>
            </CmsDataProvider>
        </>
    )
}