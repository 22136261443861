import React from "react"
import { UserService } from "../users"

export const LOCAL_STORAGE_KEY = "user"

export type UserData = {
  person: {
    email: string
    title: string
    firstName: string
    lastName: string
    language: string
    timeZone: string
    fields: {
      companyRole: string
      companyName: string
      companyPostalCode: number
      companyStreet: string
      companyCity: string
      companyCountry: string
      bookmarks: string
      companyState: string
      companyPhone: number
    }
  }
  leadToken: string
}

interface State {
  analyticsSent: boolean
  loggedIn: boolean
  loginCheckDone: boolean
  user: Maybe<UserData>
}

const defaultState: State = {
  analyticsSent: false,
  loggedIn: false,
  loginCheckDone: false,
  user: null,
}

type Action =
  | { type: "SET_LOGGED_OUT" }
  | { type: "SET_LOGGED_IN"; payload: { user: UserData } }
  | { type: "SET_USER_DATA"; payload: { user: UserData } }

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "SET_LOGGED_OUT":
      return {
        ...state,
        loggedIn: false,
        loginCheckDone: true,
        user: null,
      }
    case "SET_LOGGED_IN":
      return {
        ...state,
        loggedIn: true,
        loginCheckDone: true,
        user: action.payload.user,
      }
    case "SET_USER_DATA":
      return {
        ...state,
        user: action.payload.user,
        loginCheckDone: true,
        loggedIn: true,
      }
    default:
      return state
  }
}

export const LoginStateContext = React.createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({ state: defaultState, dispatch: () => null })

export const useLoginState = () => React.useContext(LoginStateContext)

export const LoginStateProvider: React.FC = props => {
  const [state, dispatch] = React.useReducer(reducer, defaultState)
  /* 
    React.useEffect(() => {
        const getUserDataFromBackend = async () => {
            try {
                console.log("logged in " + state.loggedIn)
                const axiosResponse = await UserService.currentUser()

                if (axiosResponse.status === 200) {
                  const userData: UserData = axiosResponse.data
                    dispatch({ type: 'SET_LOGGED_IN', payload: { user: userData } })
                }
            } catch {
                dispatch({type: 'SET_LOGGED_OUT'})
            }
        }
        getUserDataFromBackend()
    }, []) */

  return (
    <LoginStateContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LoginStateContext.Provider>
  )
}
