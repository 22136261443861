declare global {
    interface Window {
        dataLayer: GtmEvent[]
        usercentrics: any
    }
}

interface GtmEvent {
    [key: string]: string
}

export class TrackingUtil {
    public static pushToDataLayer(event: GtmEvent) {
        if (!window.dataLayer) {
            window.dataLayer = []
        }

        window.dataLayer.push(event)
    }
}
