"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SSPAssetFolderAccessor = (function () {
    function SSPAssetFolderAccessor(model) {
        this._model = model;
    }
    SSPAssetFolderAccessor.prototype.update = function (model) {
        this._model = model;
    };
    SSPAssetFolderAccessor.prototype.getChildByName = function (name) {
        var children = this.vfsChildren;
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            if (child.name === name) {
                return child;
            }
        }
        return null;
    };
    SSPAssetFolderAccessor.prototype.getChildrenByContentType = function (type) {
        var list = new Array();
        var children = this.vfsChildren;
        for (var _i = 0, children_2 = children; _i < children_2.length; _i++) {
            var child = children_2[_i];
            if (child.contentType === type) {
                list.push(child);
            }
        }
        return list;
    };
    SSPAssetFolderAccessor.prototype.getChildrenByFileFamilyAndType = function (fileFamily, fileType) {
        if (fileType === void 0) { fileType = null; }
        var list = new Array();
        var children = this.vfsChildren;
        for (var _i = 0, children_3 = children; _i < children_3.length; _i++) {
            var child = children_3[_i];
            if (child.asAsset.fileFamily === fileFamily) {
                if (!fileType || child.asAsset.fileProperties.fileType === fileType) {
                    list.push(child);
                }
            }
        }
        return list;
    };
    SSPAssetFolderAccessor.prototype.hasChild = function (uid) {
        var children = this.vfsChildren;
        for (var _i = 0, children_4 = children; _i < children_4.length; _i++) {
            var child = children_4[_i];
            if (child.uid === uid) {
                return true;
            }
        }
        return false;
    };
    Object.defineProperty(SSPAssetFolderAccessor.prototype, "vfsChildren", {
        get: function () {
            return this._model._acc.reflist('VfsChildren', false);
        },
        enumerable: false,
        configurable: true
    });
    return SSPAssetFolderAccessor;
}());
exports.default = SSPAssetFolderAccessor;
