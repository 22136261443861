// import React, { useEffect, useRef } from 'react'

import { useState } from "react"
import Cookies from "universal-cookie"
import { UserService } from "../api/users"

// const [tracker, setTracker] = useState<MediaTracker>();
// const [playerName, setPlayerName] = useState<string>();
//
// export interface TrackingData {
//     type: 'media' | 'event'
//     name?: string
//     src?: string
//     action?: string
//     category?: string
//     data?: {
//         title?: string
//         progressInSeconds?: number
//         totalLengthInSeconds?: number
//     } | null
//     context?: {
//         uid?: string
//         topics?: Array<string>
//     }
//     source?: string | null
// }

export const updateTracking = () => {
  if (!window._paq) {
    window._paq = []
  }
  window._paq.push(["trackPageView"])
  /*   UserService.currentUser().then(
        (response) => {
            if (response.status === 200) {
                const cookies = new Cookies();
                const cookiePolicy = cookies.get("cookiePolicy");

                if (cookiePolicy && cookiePolicy === "all") {
                    window._paq.push(['setUserId', response.data.leadToken])
                }
            }
            window._paq.push(['trackPageView'])
        }
    ).catch(() => {
        window._paq.push(['trackPageView'])
    }); */
}

export const customEvent = (params: any[] = []) => {
  //to debug customEvents
  // console.log('Matomo - Event', ['trackEvent', ...params])

  try {
    window._paq.push(["trackEvent", ...params])
  } catch (error) {
    console.log(error)
  }
}

export const trackTitle = (params: any[] = []) => {
  //to debug customEvents
  // console.log('Matomo - Event', ['trackEvent', ...params])

  try {
    window._paq.push(["trackPageView", ...params])
  } catch (error) {
    console.log(error)
  }
}

export const toggleAnalyticsTracking = (checked: boolean) => {
  //console.debug('ToggleAnalytics', ['checked', checked])
  try {
    checked
      ? window._paq.push(["forgetUserOptOut"])
      : window._paq.push(["optUserOut"])
  } catch (error) {
    console.debug(error)
  }
}

// export const transformTrackingEvent = (data: TrackingData) => {
//     const {type} = data
//     if (type === 'event') {
//         transformEvent(data)
//     }
//     if (type === 'media') {
//         transformMediaEvent(data)
//     }
// }
//
// const transformEvent = ({category, action, data}: TrackingData) => {
//     const trackData = [`${category}`, `${action}`, `${JSON.stringify(data)}`]
//     console.debug('Event', trackData)
//
//     window._paq.push(['trackEvent', ...trackData])
// }
//
// const transformMediaEvent = (input: TrackingData) => {
//     const {name, src, data, context, source = null} = input
//     const {topics: [altTitle] = []} = context || {}
//     const {
//         title = '',
//         totalLengthInSeconds = 0,
//         progressInSeconds = 0,
//     } = data || {title: '', totalLengthInSeconds: 0, progressInSeconds: 0}
//
//     let MA
//
//     if (typeof window !== 'undefined' && window.Matomo) {
//         MA = window?.Matomo?.MediaAnalytics
//     }
//
//     if (!MA) {
//         return false
//     }
//
//     switch (name) {
//         case 'start':
//             if (source) {
//                 setPlayerName(source)
//             }
//
//             setTracker(new MA.MediaTracker(
//                 playerName,
//                 MA.mediaType.VIDEO,
//                 src
//             ))
//             tracker.setMediaTitle(
//                 `${title || altTitle || src}${
//                     context ? `||${JSON.stringify({...context})}` : ''
//                 }`
//             )
//             tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
//
//             tracker.play() // need to come from showroom
//
//             break
//         default:
//             if (!tracker) {
//                 console.warn('Matomo Media was not initialized')
//
//                 return
//             }
//             switch (name) {
//                 case 'play':
//                     tracker.play()
//                     break
//                 case 'pause':
//                     tracker.pause()
//                     break
//                 case 'ended':
//                     tracker.finish()
//                     break
//                 case 'timeupdate':
//                     tracker.setMediaProgressInSeconds(progressInSeconds)
//                     tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
//                     tracker.update()
//                     break
//                 case 'seeked':
//                     tracker.seekStart()
//                     tracker.setMediaProgressInSeconds(progressInSeconds)
//                     tracker.setMediaTotalLengthInSeconds(totalLengthInSeconds)
//                     tracker.seekFinish()
//                     break
//             }
//     }

/*    const tracker = new MA.MediaTracker('myPlayerName', mediaType, resource);
 */
