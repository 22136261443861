let previousPath: string
let currentPath: string

export function setCurrentPath(path: string): void {
    if (previousPath !== currentPath && currentPath !== path) {
        previousPath = currentPath
    }
    currentPath = path
}

export function getPreviousPath(): string {
    return previousPath
}