"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.httpReq = void 0;
exports.httpReq = function (method, url, data) {
    return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        request.open(method, url, true);
        request.onload = function () {
            resolve(request);
        };
        request.onerror = function () {
            reject(request);
        };
        request.send(data);
    });
};
