"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLc = exports.toLccc = void 0;
exports.toLccc = function (lcOrLccc, cc, separator) {
    if (cc === void 0) { cc = null; }
    if (separator === void 0) { separator = '-'; }
    var lc = lcOrLccc;
    if (cc === null) {
        var ar = /(.*)[_\-](.*)/.exec(lcOrLccc);
        if (Array.isArray(ar) && ar.length === 3) {
            lc = ar[1];
            cc = ar[2];
        }
        else {
            return "de" + separator + "DE";
        }
    }
    return lc.toLowerCase() + separator + cc.toUpperCase();
};
exports.toLc = function (lcOrLccc, cc, separator) {
    if (cc === void 0) { cc = null; }
    if (separator === void 0) { separator = '-'; }
    return exports.toLccc(lcOrLccc, cc, separator).split(separator)[0];
};
