"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var W3CDDateRangeAccessor = (function () {
    function W3CDDateRangeAccessor(start, end) {
        if (end === void 0) { end = null; }
        this.start = start;
        this.end = end;
    }
    Object.defineProperty(W3CDDateRangeAccessor.prototype, "hasEnd", {
        get: function () {
            return this.end !== null;
        },
        enumerable: false,
        configurable: true
    });
    W3CDDateRangeAccessor.prototype.isInRange = function (date) {
        if (!this.end) {
            return date.getTime() >= this.start.getTime();
        }
        return date.getTime() >= this.start.getTime() && date.getTime() < this.end.getTime();
    };
    return W3CDDateRangeAccessor;
}());
exports.default = W3CDDateRangeAccessor;
